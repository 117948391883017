import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { store } from "../../../Store";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";
import useIsMobile from "../../../../js/utilities/useIsMobile";
import { useNetworkStatus } from "../../../../hooks/use-network-status";
import { useAdvancedSearch } from "../../../../hooks/advanced-search/use-advanced-search";

export const SearchBooksButton = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
	const globalState = useContext(store);
	const { state } = globalState;
	const accebilidadState = useContext(AccesibilidadContext);

	const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

	const location = useLocation();
	const isMobile = useIsMobile();
	const networkStatus = useNetworkStatus();

	const [esTemaClasico, setEsTemaClasico] = useState(true);
	const [buscarOver, setBuscarOver] = useState(false);

	const advancedSearch = useAdvancedSearch();
	const advancedSearchButtonRef = useRef(null);

	/*Actualiza el estado de EsTemaClasico. */
	useEffect(() => {
		let tema = accebilidadState?.temaActual !== "clasico" ? false : true;
		setEsTemaClasico(tema);
	}, [accebilidadState]);

	/*Asigna el botón que abre las opciones de búsqueda */
	useEffect(() => {
		if (advancedSearchButtonRef.current) {
			globalState.dispatch({
				type: "CHANGE",
				path: "advancedSearch.triggerButton",
				value: advancedSearchButtonRef,
			});
		}
	}, [advancedSearchButtonRef]);

	return (
		<>
			{!isMobile &&
				isLogged &&
				networkStatus.online &&
				!location.pathname.includes("/visor") &&
				state.cantidadLibros !== "NULL" &&
				state.cantidadLibros > 40 && (
					<li
						className={`btn-buscar mx-2 
          ${
				isLogged &&
				!location.pathname.includes("/visor") &&
				state.cantidadLibros !== "NULL" &&
				state.cantidadLibros > 40
					? buscarOver
						? "btn-buscar"
						: "btn-buscar-general"
					: "btn-buscar"
			}
        `}
					>
						<button
							ref={advancedSearchButtonRef}
							className={`focusable-red ${
								advancedSearch?.state?.visible && "activeMode"
							} font-weight-bold`}
							onClick={() => {
								advancedSearch.setVisible(!advancedSearch?.state?.visible);
							}}
							onMouseOver={() => setBuscarOver(true)}
							onMouseLeave={() => setBuscarOver(false)}
							style={{
								color:
									isLogged &&
									!location.pathname.includes("/visor") &&
									state.cantidadLibros !== "NULL" &&
									state.cantidadLibros > 40
										? buscarOver || advancedSearch?.state?.visible
											? ""
											: esTemaClasico
											? state.datosHeader?.colorLetra !== null
												? state.datosHeader?.colorLetra
												: "var(--onsecondary-color)"
											: "var(--primary-inverse)"
										: "",
								borderColor:
									isLogged &&
									!location.pathname.includes("/visor") &&
									state.cantidadLibros !== "NULL" &&
									state.cantidadLibros > 40
										? advancedSearch?.state?.visible
											? " var(--onprimary-inverse)"
											: esTemaClasico
											? state.datosHeader?.colorLetra !== null
												? state.datosHeader?.colorLetra
												: "var(--onsecondary-color)"
											: "var(--primary-inverse)"
										: "",
							}}
						>
							{/* <i
								className="d-none d-xl-inline-block search-icon fas fa-search mr-2"
								aria-hidden="true"
							></i>{" "} */}
							{textosInterfaz?.buscar || "Buscar"}
						</button>
					</li>
				)}
		</>
	);
};
