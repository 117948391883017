import React from "react";
import { Link } from "react-router-dom";
import { Img } from "./shared/img";
import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants";

/**
 * Retorna la franja de FAQs y Contáctanos
 * @returns {JSX.Element} Faqs
 */
export const CallToAction = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "home", lang: lang });
    const faqsTexts = textos?.faqs;

    return (
        <div className="p-3 py-4 red--bg onred--color">
            <div className="container">
                <div className="d-md-flex justify-content-center">
                    <div
                        className="col-12 col-md-7 col-lg d-flex flex-column justify-content-center align-items-center"
                        style={{ maxWidth: 600 }}
                    >
                        <div>
                            <h1
                                className="text-left secondary-font w-100 text-24"
                                style={{ fontSize: "1.3em" }}
                            >
                                {faqsTexts?.mensaje || "¿Quieres que estos libros estén disponibles en tu biblioteca público o en tu colegio?"}
                            </h1>

                            <div className="py-3">
                                <Link
                                    className="btn btn-primary p-2 px-3 my-3 mr-3 focusable-primary"
                                    to="/tienda/institucional/es/cita"
                                >{faqsTexts?.contactanos || 'Contáctanos'}</Link>
                                <Link
                                    className="btn btn-secondary px-3 focusable-red"
                                    to="/ayuda"
                                >{faqsTexts?.fqs || 'FAQs'}</Link>
                            </div>
                        </div>

                    </div>
                    <div className="col d-flex">
                        <Img
                            className="img-fluid accesible-image"
                            src="/img/que-es/home/demo/demo-running.png"
                            alt="newsletter-speaker"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}