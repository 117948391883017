import React, { useState, useEffect, useRef, useContext } from "react";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { ASSETS_URL, store } from "../../../Store";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";

import { useAccessibility } from "../../../../hooks/useAccessibility";
import { useGeneralHeaderDropdown } from "../../../../hooks/header/useGeneralHeaderDropdown";

import { DropdownMiCuenta } from "./DropdownMiCuenta";
import { DropdownComunidad } from "../../public-header/components/DropdownComunidad";
import { NotificationButton } from "../../components/notification-button";
import { SearchBooksButton } from "./search-books-button";
import { LangButton } from "../../../language/lang-button";

/**
 * Componente con las opciones del header general
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns {JSX.Element} NavbarNav
 */
export const NavbarNav = ({ textosInterfaz }) => {

    const globalState = useContext(store);
    const { state } = globalState;
    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

    const [openDropdownCommunity, setOpenDropdownCommunity] = useState(false);
    const comunidadRef = useRef(null);

    const accessibility = useAccessibility();
    const accessibilityButtonRef = useRef(null);

    const showADropdown = useGeneralHeaderDropdown();
    const visible = showADropdown?.state?.visible;
    const dropdownButtonRef = useRef(null);

    const [esTemaClasico, setEsTemaClasico] = useState(true);
    const accebilidadState = useContext(AccesibilidadContext);

    const [comunidadOver, setComunidadOver] = useState(false);
    const [accOver, setAccOver] = useState(false);

    const location = useLocation();


    /*Actualiza el estado de EsTemaClasico. */
    useEffect(() => {
        let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
        setEsTemaClasico(tema);
    }, [accebilidadState])

    /*Asigna el botón que abre el dropdown del header general */
    useEffect(() => {
        if (dropdownButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "generalHeaderDropdown.triggerButtonRef",
                value: dropdownButtonRef,
            });
        }
    }, [dropdownButtonRef.current]);

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    /*cierra el dropdown al hacer scroll al inicio de la página */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false);
                setOpenDropdownCommunity(false);
            }
        });
    }, [accessibility.state.visible])



    return (
        <ul className="option-nav d-flex justify-content-end aling-items-center">

            
                <SearchBooksButton/>
          
            <li
                ref={comunidadRef}
                className={`${isLogged ?
                    (location.pathname === '/club-makemake' || location.pathname === '/blog' || location.pathname === '/centro-de-recursos' || comunidadOver) ?
                        "btn-comunidad"
                        :
                        "btn-comunidad-general"
                    :
                    "btn-comunidad"
                    } position-relative mx-2`}
            >
                <button
                    className={`focusable-red 
                        ${(location.pathname === '/club-makemake' || location.pathname === '/blog' || location.pathname === '/centro-de-recursos') && "activeMode"}`}
                    onClick={() => {
                        setOpenDropdownCommunity(!openDropdownCommunity);
                    }}

                    onMouseOver={() => setComunidadOver(true)}
                    onMouseLeave={() => setComunidadOver(false)}

                    style={{
                        color: isLogged ?
                            (location.pathname === '/club-makemake' || location.pathname === '/blog' || location.pathname === '/centro-de-recursos' || comunidadOver) ?
                                "var(--onprimary-inverse)"
                                :
                                esTemaClasico ?
                                    state.datosHeader?.colorLetra !== null ?
                                        state.datosHeader?.colorLetra
                                        :
                                        'var(--onsecondary-color)'
                                    :
                                    "var(--primary-inverse)"
                            :
                            "",
                        borderColor: isLogged ?
                            (location.pathname === '/club-makemake' || location.pathname === '/blog' || location.pathname === '/centro-de-recursos' || comunidadOver) ?
                                " var(--onprimary-inverse)"
                                :
                                esTemaClasico ?
                                    state.datosHeader?.colorLetra !== null ?
                                        state.datosHeader?.colorLetra
                                        :
                                        'var(--onsecondary-color)'
                                    : " var(--primary-inverse)" : "",
                    }}
                >
                    {textosInterfaz?.comunidad || 'Comunidad'}
                </button>

                <div className={`dropdownMenu dropDownComunidad ${openDropdownCommunity ? 'activeMode' : 'inactiveMode'} `}>
                    <DropdownComunidad
                        setOpenDropdown={setOpenDropdownCommunity}
                        openDropdown={openDropdownCommunity}
                        textosInterfaz={textosInterfaz}
                    />
                </div>
            </li>

            <li className="position-relative mx-lg-3">
                <button
                    ref={dropdownButtonRef}
                    className={`btn-cuenta-general focusable-red ${(location.pathname === '/favoritos' || location.pathname === '/mi-panel/mi-cuenta' || location.pathname === '/ayuda' || location.pathname === '/descargados' || visible) && 'active-cuenta'}`}
                    onClick={() => {
                        setOpenDropdownCommunity(false);
                        showADropdown.setVisible(!showADropdown?.state?.visible);
                    }}

                    style={{
                        borderColor: isLogged ?
                            esTemaClasico ?
                                state.datosHeader?.colorLetra !== null ?
                                    state.datosHeader?.colorLetra
                                    :
                                    'var(--onsecondary-color)'
                                : "var(--primary-inverse)"
                            : "",
                        color: isLogged ?
                            esTemaClasico ?
                                state.datosHeader?.colorLetra !== null ?
                                    state.datosHeader?.colorLetra
                                    :
                                    'var(--onsecondary-color)'
                                :
                                "var(--primary-inverse)"
                            :
                            ""
                    }}
                >
                    <div
                        className="mr-1 mr-lg-3 btn-avatar"
                        style={
                            { backgroundImage: `url('${isLogged ? `${ASSETS_URL}${state.datosHeader?.avatar}` : '/img/profile.png'}')` }
                        }
                    ></div>
                    <span className="una-linea-texto">{textosInterfaz?.mi_cuenta || 'Mi cuenta'}</span>
                    <i className={`fas fa-chevron-down fa-sm ml-2 arrow d-none d-lg-block ${visible && "active-arrow"}`}></i>
                </button>

                <DropdownMiCuenta textosInterfaz={textosInterfaz} />
            </li>

            <li className={"mx-1"}>
                <button
                    ref={accessibilityButtonRef}
                    className={`focusable-red w-100 h-100 px-2`}
                    onClick={() => { accessibility.setVisible(!accessibility?.state?.visible) }}

                    onMouseOver={() => setAccOver(true)}
                    onMouseLeave={() => setAccOver(false)}

                    style={{
                        color: isLogged ?
                            (accOver || accessibility?.state?.visible) ?
                                "var(--onprimary-inverse)"
                                :
                                esTemaClasico ?
                                    state.datosHeader?.colorLetra !== null ?
                                        state.datosHeader?.colorLetra
                                        :
                                        'var(--onsecondary-color)'
                                    :
                                    "var(--primary-inverse)"
                            :
                            "",
                        background: isLogged ? (accOver || accessibility?.state?.visible) ? "var(--primary-inverse)" : "" : ""
                    }}
                >
                    <Icon
                        /* icon="material-symbols:settings" */
                        icon="fontisto:universal-acces"
                        width={20}
                    />
                </button>
            </li>

            <li>
                <LangButton/>
            </li>

            <NotificationButton />
        </ul>
    )
}