import React, { useRef } from "react";
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { store } from "../../components/Store";
import { BaseSelect } from "../../components/shared/base-select";
import { Icon } from "@iconify/react";
import { useTranslations } from "../../hooks/use-translations";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { useInstitutionSearch } from "../hooks/use-institution-search";
import { Accordion } from "../../components/shared/accordion";

/**
 * Componente de selección de bibliotecas, cuando el usuario selecciona una biblioteca el componente guarda en el estado global la información completa de la biblioteca seleccionada 
   para que pueda ser manipulada por otros componentes como el de autenticación (InstitutionalAuth), la información en el estado global esta asociada al objeto 'institutionalAuth' especificamente las propiedades 'institutionalAuth.isSelectedLibrary' y 'institutionalAuth.selectedLibrary'
 * @returns {JSX.Element} Elemento JSX que representa el componente de selección de bibliotecas.
 */
export const InstitutionSelect = ({ onExpand, onCollapse }) => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const history = useHistory();
	const [expanded, setExpanded] = useState(false);
	const [currentSearchText, setCurrentSearchText] = useState("");
	const institutionSearch = useInstitutionSearch({ text: currentSearchText });
	const institutions = institutionSearch?.matches;
	const fetching = appState?.institutionalAuth?.fetchCompleted ? false : true;
	const inputContainerRef = useRef(null);

	const typingHandler = ({ text }) => {
		setCurrentSearchText(text);
		setExpanded(true);
	};

	const handleSelection = (library) => {
		setExpanded(false);
		storeContext.dispatch({
			type: "CHANGE",
			path: "institutionalAuth.selectedLibrary",
			value: library,
		});
		storeContext.dispatch({
			type: "CHANGE",
			path: "institutionalAuth.isSelectedLibrary",
			value: true,
		});
		history.push("/institutional-auth/search/auth");
	};

	useEffect(() => {
		if (expanded) {
			onExpand && onExpand();
		} else {
			onCollapse && onCollapse();
		}
	}, [expanded]);

	useEffect(() => {
		// cuando se haga click en el contenedor del input, la pagina hará scroll hasta el input para que siempre este correctamente visible.
		const containerRef = inputContainerRef.current;
		if (containerRef) {
			containerRef.onclick = () => {
				const yOffset = 80;
				const elementPosition = containerRef.getBoundingClientRect().top + window.scrollY;
				window.scrollTo({
					top: elementPosition - yOffset,
				});
			};
		}
	}, [inputContainerRef.current]);

	function highlightMatch(text, query) {
		if (!query) return text;

		// Crear una expresión regular para la búsqueda, insensible a mayúsculas/minúsculas
		const regex = new RegExp(`(${query})`, "gi");

		// Dividir el texto y mapear las partes
		return text.split(regex).map((part, index) =>
			part.toLowerCase() === query.toLowerCase() ? (
				<span key={index} style={{ backgroundColor: "yellow", fontWeight: "bold" }}>
					{part}
				</span>
			) : (
				part
			)
		);
	}

	const sortBuildings = (buildings) => {
		if (!buildings || !Array.isArray(buildings)) return [];
		return buildings.sort((a, b) => {
			if (a?.nombreSede < b?.nombreSede) {
				return -1;
			}
			if (a?.nombreSede > b?.nombreSede) {
				return 1;
			}
			return 0;
		});
	};

	return (
		<>
			{fetching ? (
				<Icon
					icon="fa:spinner"
					width={40}
					className={`spinner-rotation onsecondary--color`}
				/>
			) : (
				<div className="p-0 col-lg-7 library-select">
					<BaseSelect.Container expanded={expanded} setExpanded={setExpanded} zIndex={1}>
						<BaseSelect.Button>
							<div
								ref={inputContainerRef}
								className="text-field py-4 dark-input-wrapper focusable-by-children-primary form-control overflow-hidden p-0 m-0 d-flex justify-content-center align-items-center"
							>
								<div className="flex-grow-1 px-2 overflow-hidden">
									<input
										className="w-100 p-0 m-0"
										style={{ marginRight: 5 }}
										placeholder={
											textos?.busqueda?.input?.placeholder ||
											"Ingresa el nombre de tu institución"
										}
										onFocus={() => setExpanded(true)}
										onClick={() => setExpanded(true)}
										onChange={(e) => typingHandler({ text: e?.target?.value })}
									/>
								</div>

								<div className="d-flex justify-content-center align-items-center px-2 flex-grow">
									<Icon
										icon={"material-symbols:search"}
										className="search-icon"
									/>
									<Icon
										icon={"fe:arrow-down"}
										className="select-icon"
										onClick={() => setExpanded(!expanded)}
									/>
								</div>
							</div>
						</BaseSelect.Button>
						<BaseSelect.List maxHeight={"45vh"}>
							<div className="library-list">
								<LazyLoadComponent>
									{institutions?.length <= 0 && (
										<div>
											<p
												className="onbackground-primary--color text-center"
												style={{ fontWeight: "bold", fontSize: "1.5rem" }}
											>
												{textos?.busqueda?.input?.sin_resultados ||
													"No se encontraron coincidencias en la búsqueda de la institución."}
											</p>
											<p
												className="onbackground-primary--color my-2 text-center"
												style={{ fontSize: "1.2rem" }}
											>
												{textos?.busqueda?.input?.sin_resultados_contacto ||
													"Si deseas adquirir MakeMake para tu institución, no dudes en contactarnos"}{" "}
												<a
													className="font-weight-bold"
													style={{ textDecoration: "underline" }}
													href="/tienda/institucional/cita"
													target="_blank"
													rel="noopener noreferrer"
												>
													{textos?.busqueda?.input
														?.sin_resultados_contacto2 || "acá"}
													.
												</a>
											</p>
										</div>
									)}
									<Accordion.Container>
										{institutions?.map((institution, i) => {
											return (
												<Accordion.Item key={i} itemId={`item-${i}`}>
													<Accordion.ItemButton
														className={'d-flex justify-content-between align-items-center '}
														label={`${institution?.nombre} - ${institution?.pais}`}
													/>
													<Accordion.Content>
														<ul>
															{sortBuildings(institution?.sedes)?.map((item, j) => {
																return (
																	<li key={j}>
																		<button
																			className="w-100 library-item text-left" 
																			onClick={() =>
																				handleSelection(
																					{instituto: institution, ...item}
																				)
																			}
																		>
																			{item?.nombreSede}
																		</button>
																	</li>
																);
															})}
														</ul>
													</Accordion.Content>
												</Accordion.Item>
											);
										})}
									</Accordion.Container>
								</LazyLoadComponent>
							</div>
						</BaseSelect.List>
					</BaseSelect.Container>
				</div>
			)}
		</>
	);
};
