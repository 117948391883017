import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BOOKS_PREVIEW_SEARCH } from "./data/book_preview_search";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import * as _ from "lodash";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { Img } from "../shared/img";

/**
 * Retorna el texto introductorio en libros y los filtros de busqueda
 * @param {object} texts contiene los textos de interfaz
 * @returns {JSX.Element} IntroHome
 */
export const FilterBooks = () => {

    const options = [...BOOKS_PREVIEW_SEARCH];
    const [currentOption, setCurrentOption] = useState();

    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textos = useTranslations({ section: "home", lang: lang });
    const booksPreviewTexts = textos?.filtro;

    return (
        <section className="container my-5">
            <div className="row">
                <div className="col-md-4 order-md-1">
                    <Img src="/img/matilda-libro.png" alt="blop sostiene una bandera" className="img-fluid" style={{ maxWidth: 250 }} />
                </div>
                <div className="col-md-8 order-0">
                    <div className="d-flex align-items-center h-100">
                        <p className="text-24 secondary-font onbackground-primary--color font-weight-bold py-4 text-center text-md-left">
                            {textos?.sin_login?.coleccion || 'MakeMake ofrece a tu colegio y biblioteca acceso a la mejor colección de libros de América Latina para niños entre los 3 y 15 años'}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row px-0 my-5 ">
                <div className="d-flex flex-wrap justify-content-center align-items-center focusable-primary">
                    {options?.map((option, i) => {
                        return (
                            <Link
                                key={i}
                                onClick={() => setCurrentOption(option)}
                                style={{ fontWeight: 500 }}
                                className={`p-2 m-1 px-3 preview-button-option focusable-red ${currentOption?.value === option?.value ? "selected" : ""
                                    }`}
                                to={option?.type === "gratis" ? `/coleccion/libros-gratuitos/` : `/busqueda?${option?.type}=${option?.value}`}
                            >
                                {_.get(booksPreviewTexts, option?.text?.translationPath) ||
                                    option?.text?.default ||
                                    "N/A"}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </section>
    )
}