import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { ContentHeader } from "./components/content-header";
import { ProminentClients } from "../../components/prominent-clients";
import { ArrowRibbon } from "./components/arrow-ribbon";
import { Icon } from "@iconify/react";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";
import { ALL_LIBRARYS_IMAGES } from "../../shared/data/libraries-data";
import { ALL_INSTITUTIONS_IMAGES } from "../../shared/data/institutions-data";

export const SpecialHispanic = () => {
	const accesibility = useContext(AccesibilidadContext);
	const isHighContrast = accesibility.temaActual === TemasDisponibles.altoContraste.id;
	const title = "Hispanic Heritage SALE!";
	const mainDescription =
		"MakeMake offers schools and public libraries a curated collection of the best Authentic Latin American eBooks for kids ages 3 – 12. Subscribe today and enjoy up to 100 bonus eBooks!";
	const panels = {
		schedule: "schedule",

		demo: "demo",
	};
	const [currentPanel, setCurrentPanel] = useState(panels.schedule);

	const usa_clients = [
		...ALL_LIBRARYS_IMAGES.filter((library) => library.location.toLowerCase() === "usa"),
		...ALL_INSTITUTIONS_IMAGES.filter((library) => library.location.toLowerCase() === "usa"),
	];

	const changePanel = (panelId) => {
		setCurrentPanel(panelId);
	};

	const codeLabel = () => {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<p
					className="p-2 primary-inverse--bg onprimary-inverse--color text-center"
					style={{ borderRadius: ".5rem" }}
				>
					Code: HISPANIC2024. Valid until December 31st 2024.
				</p>
			</div>
		);
	};

	const logoRights = () => {
		return (
			<p className="onbackground-primary--color" style={{ fontSize: ".7em" }}>
				Ilustracion de Fabiola Graullera, del libro René tiene dos apellidos. Arte Público
				Press
			</p>
		);
	};

	return (
		<>
			<Helmet>
				<title>MakeMake - {title}</title>
				<meta name="description" content={mainDescription} />
			</Helmet>
			<div className="pautas">
				<ContentHeader
					title={title}
					backgroundColor={isHighContrast ? "var(--secondary-inverse)" : "#ffd401"}
					color={isHighContrast ? "var(--onsecondary-inverse)" : "#000"}
				/>
				<div className="container pautas-content p-0">
					<div
						className={`${
							isHighContrast ? "" : "ilustration-background"
						} row p-0 m-0 mb-4`}
					>
						<div className="col-12 col-lg-5 col-xl-4 p-0">
							<div className="col-12 p-0">
								<img
									className="img-fluid"
									src="/img/pautas/hispanic2024.png"
									alt="illustration"
								/>
							</div>
							<div className="col-12 px-3">{logoRights()}</div>
						</div>
						<div className="col p-0">
							<p
								className="secondary-font p-3 my-2 main-description onbackground-primary--color"
								style={{ fontWeight: 600 }}
							>
								{mainDescription}
							</p>
						</div>
						<div className="col-12 row p-0 m-0">
							<div className="col-4 d-none d-xl-block py-5">{codeLabel()}</div>
							<div className="col p-0">
								<div className="row p-0 m-0 align-items-center">
									<div className="col-12 col-lg-6 col-xl p-0">
										<div className="book-package-table d-flex flex-column m-3">
											<div className="table-header row p-0 m-0 table-border bottom">
												<p
													className="secondary-inverse--color p-2 col-6 d-flex justify-content-center align-items-center text-center table-border right"
													style={{ fontWeight: 600 }}
												>
													Subscription book package
												</p>
												<p
													className="secondary-inverse--color p-2 col-6 d-flex justify-content-center align-items-center text-center"
													style={{ fontWeight: 600 }}
												>
													Bonus books
												</p>
											</div>
											<div className="table-content d-flex flex-column w-100">
												<p className="table-row d-flex w-100 justify-content-around align-items-center table-border bottom">
													<span className="">300</span>
													<span className="dashed-arrow">
														<span className="dash"></span>
														<Icon
															icon="ic:round-play-arrow"
															style={{
																transform:
																	"scale(1.3) translateX(-15%)",
															}}
														/>
													</span>
													<span className="">60</span>
												</p>

												<p className="table-row d-flex w-100 justify-content-around table-border bottom">
													<span>400</span>
													<span className="dashed-arrow">
														<span className="dash"></span>
														<Icon
															icon="ic:round-play-arrow"
															style={{
																transform:
																	"scale(1.3) translateX(-15%)",
															}}
														/>
													</span>
													<span>80</span>
												</p>

												<p className="table-row d-flex w-100 justify-content-around">
													<span>500</span>
													<span className="dashed-arrow">
														<span className="dash"></span>
														<Icon
															icon="ic:round-play-arrow"
															style={{
																transform:
																	"scale(1.3) translateX(-15%)",
															}}
														/>
													</span>
													<span>100</span>
												</p>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-6  col-xl p-0">
										<a
											href="https://www.slj.com/story/makemake-spanish-ebooks-for-children-database-review"
											target="_blank"
											rel="noopener noreferrer"
											className="focusable-red d-flex justify-content-end align-items-center my-4"
											style={{
												position: "relative",
												minHeight: 100,
												fontWeight: 600,
											}}
										>
											<span
												className="w-100 h-100 top-0 left-0"
												style={{ position: "absolute", zIndex: 0 }}
											>
												<ArrowRibbon
													color={
														isHighContrast
															? "var(--secondary-inverse)"
															: "#6666cc"
													}
												/>
											</span>
											<span
												className="ms-2 onsecondary-inverse--color col-10 col-xl-11 p-3 italic"
												style={{ zIndex: 2 }}
											>
												<cite style={{ fontSize: "0.9em" }}>
													"An exciting resource that will help bridge the
													gaps in Spanish collections for youth"
												</cite>
												<br />
												<span style={{ fontSize: "0.8em" }}>
													{" "}
													School Library Journal
												</span>
												<br />
												<p className="d-flex justify-content-end align-items-center">
													<span
														style={{
															fontSize: "0.8em",
															borderBottom:
																"solid 2px var(--onsecondary-inverse)",
														}}
													>
														{" "}
														Read here
													</span>
												</p>
											</span>
										</a>
									</div>
									<div className="col-12 my-3">
										<p
											className="secondary-font onbackground-primary--color"
											style={{ fontWeight: 400, fontSize: "1.3em" }}
										>
											With MakeMake you get unlimited access for all users!
											Our prices include all fees, plus fully bilingual
											services such as monthly newsletters, video tutorials
											and outreach materials designed to support staff who
											serve Hispanic communities.
										</p>
									</div>
									<div className="col-12 d-block d-xl-none my-5">
										{codeLabel()}
									</div>
									<div className="col-12" style={{ height: 25 }}></div>
								</div>
							</div>
						</div>
					</div>
					<div className="dial-container col-12">
						<div className=" d-flex flex-column justify-content-center align-items-center">
							<div className="dial px-4 py-3 primary-inverse--bg onprimary-inverse--color">
								GET THIS DEAL
							</div>
							<Icon
								icon="ic:round-double-arrow"
								width={90}
								className="dial-icon secondary-inverse--color"
							/>
						</div>
					</div>
					{/* <TabPanel.Box
						currentPanelId={currentPanel}
						className={"d-lg-flex pautas-contact-panel mt-0 pt-0"}
					>
						<TabPanel.Tabs className={"tabs col-lg-4 col-xl-3 pt-lg-5 px-0"}>
							<TabPanel.Tab
								name={"Schedule an appointment"}
								onClick={() => changePanel(panels.schedule)}
								className={`focusable-red tab ${
									currentPanel === panels.schedule ? "current" : ""
								}`}
							/>
							<TabPanel.Tab
								name={"Request more information"}
								onClick={() => changePanel(panels.demo)}
								className={`focusable-red tab ${
									currentPanel === panels.demo ? "current" : ""
								}`}
							/>
						</TabPanel.Tabs>
						<TabPanel.Panel
							panelId={panels.schedule}
							className={"panel col-lg-8 col-xl-9 mt-5 mt-lg-0"}
						>
							<RequestAppointment />
						</TabPanel.Panel>
						<TabPanel.Panel
							panelId={panels.demo}
							className={"panel col-lg-8 col-xl-9 mt-5 mt-lg-0"}
						>
							<RequestDemo />
						</TabPanel.Panel>
					</TabPanel.Box> */}

					<div className="d-flex justify-content-around align-items-center pb-4">
						<div className="row w-100 col-12 justify-content-center">
							<a
								className="my-2 pauta-cta-link focusable-red p-3 mx-2 text-center d-flex justify-content-center align-items-center"
								href="https://makemake.com.co/tienda/institucional/cita?idioma=en"
								target="_blank"
								rel="noopener noreferrer"
							>
								Schedule Appointment with <br /> Catalina
							</a>
							<a
								className="my-2 pauta-cta-link focusable-red p-3 mx-2 text-center d-flex justify-content-center align-items-center"
								href="https://makemake.com.co/tienda/institucional/en/demo"
								target="_blank"
								rel="noopener noreferrer"
							>
								Request more information
							</a>
						</div>
					</div>
				</div>
				<ProminentClients staticLang={"en"} customClients={usa_clients} />
			</div>
		</>
	);
};
