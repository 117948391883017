import React, { useContext, useEffect, memo } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { useDefaultLangByCountry } from "../hooks/use-default-lang-by-country";
import { useTranslations } from "../hooks/use-translations";

import { BooksSectionCollection } from "./components/books-section.js";
import { GenericCollectionRowTitle, CollectionRow } from "./components/row-section.js";
import { Newsletter } from "../que-es/home/components/newsletter";
import { CallToAction } from "../components/callToAction";
import { store } from "../components/Store";
import { Titles } from "../components/Titles";
import { SkeletonBooks } from "./components/skeletonBooks.js";

import { OurPublishersBooks } from "./components/our-publishers-books.js";
import { LANGUAGE_RULE_COUNTRIES } from "../config/constants.js";
import { FilterBooks } from "../components/FilterBooks/filterBooks.js";
import { useUserHomeData } from "../hooks/home/use-user-home.js";

import { Helmet } from "react-helmet";


/**
 * Componente que muestra la página de cátalogo de libros.
 * @param {Object} props - Props del componente.
 * @param {Object} props.data - Datos de la página de inicio del usuario.
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio del usuario.
 */
export const Libros = memo(() => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
    const textosHome = useTranslations({ section: "home", lang: lang });
    const homeData = useUserHomeData();

    const textos = textosHome?.sin_login || {};
    //const homeData = data?.data;
    const globalState = useContext(store);

    const loading = globalState?.state?.userBooksData?.loading && globalState?.state?.userBooksData?.loadCompleted === false && globalState?.state?.cantidadLibros === -1;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Helmet>
                <title>{textosHome?.metadata?.title || 'MakeMake - Libros'}</title>
                <meta name="description" content={textosHome?.metadata?.description || 'Explora el catálogo de MakeMake, una colección de libros narrativos e informativos para niños y jóvenes de altísima calidad.'} />
            </Helmet>

            {
                !loading && homeData?.fetching ?
                    //<SkeletonHomeCollection />
                    <SkeletonBooks />
                    :

                    <div className="section-libros">
                        <Titles
                            hexBackground="#6666CC"
                            color="onsecondary--color"
                            title={textos?.titulo || 'Conoce la colección'}
                        />


                        <LazyLoadComponent threshold={.5}>

                            <FilterBooks />

                            <CollectionRow
                                containerProps={{
                                    className: 'home-row-yellow'
                                }}
                                titleElement={
                                    <GenericCollectionRowTitle text={textos?.libros_interactivos || 'Libros Interactivos'} />
                                }
                            >

                                <BooksSectionCollection books={homeData?.data?.LibrosInteractivos} autoPlayDelay={5000} />
                            </CollectionRow>

                            <OurPublishersBooks />


                            <Newsletter />


                            <CollectionRow
                                containerProps={{
                                    className: 'home-row-yellow'
                                }}
                                titleElement={
                                    <GenericCollectionRowTitle text={textos?.libros_recomendados || 'Nuestros libros recomendados'} />
                                }>
                                <BooksSectionCollection books={homeData?.data?.librosRecomendados} autoPlayDelay={5500} />
                            </CollectionRow>

                            <CallToAction />

                        </LazyLoadComponent>

                    </div>
            }
        </>

    )
})