import React, { useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { store } from "../../../Store";

import { IconBlog } from "./icons/IconBlog";
import { IconComunidad } from "./icons/IconComunidad";
import { ExploreIcon } from "../../components/explore-icon";

import { useAccessibility } from "../../../../hooks/useAccessibility";
import { usePublicHeaderDropdown } from "../../../../hooks/header/usePublicHeaderDropdown";
import { DropdownNotificationButton } from "../../components/notification-button";


/**
 * Componente que devuelve el dropdown con las opciones en modo responsive. 
 * NO LOGUEADO (blog, comunidad, notificaciones, explorar, ajustes y contacto)
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns {JSX.Element} DropdownResponsive
 */
export const DropdownResponsive = ({ textosInterfaz }) => {
    const location = useLocation();

    const globalState = useContext(store);
    const accessibility = useAccessibility();
    const accessibilityButtonRef = useRef(null);

    const showADropdown = usePublicHeaderDropdown();
    const visibleDropdown = showADropdown?.state?.visible;

    const triggerButton = globalState?.state?.publicHeaderDropdown?.triggerButtonRef;
    const ref = useRef(null);

    /**Función que permite cerrar el dropdown dando click fuera de él
    * @param {event} event 
    */
    const handleOutsideClick = (event) => {
        if (triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
            showADropdown.setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [triggerButton]);

    /*cierra el dropdown al hacer scroll al inicio de la página */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false);
            }
        });
    }, [visibleDropdown]);

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    return (
        <div className={`dropdownMenuResponsive ${visibleDropdown ? 'activeMode' : 'inactiveMode'} `}>
            <ul ref={ref}>
                {location.pathname !== '/institucional/formulario' ?
                    <>
                        <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
                            <Link
                                to="/blog"
                                className="link-principal d-flex justify-content-center align-items-center focusable-red"
                                onClick={()=>showADropdown.setVisible(false)}
                            >
                                <IconBlog color={'var(--onprimary-color)'} className="mx-2" />
                                {textosInterfaz?.blog || 'Blog'}
                            </Link>
                        </li>
                        <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
                            <Link
                                to="/club-makemake"
                                className="link-principal d-flex justify-content-center align-items-center focusable-red"
                                onClick={()=>showADropdown.setVisible(false)}
                            >
                                <IconComunidad color={'var(--onprimary-color)'} className="mx-2" />
                                {textosInterfaz?.comunidad || 'Comunidad'}
                            </Link>
                        </li>
                        <DropdownNotificationButton 
                            visibleDropdown={visibleDropdown} 
                            onClick={()=>showADropdown.setVisible(false)} 
                            translations={textosInterfaz} />
                    </>
                    :

                    <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
                        <Link
                            to={"/"}
                            className="link-principal d-flex justify-content-center align-items-center focusable-red"
                            onClick={()=>showADropdown.setVisible(false)}
                        >
                            <ExploreIcon colorLetra={'var(--onprimary-color)'} className="mx-2" />

                            {textosInterfaz?.explorar || 'Explorar'}
                        </Link>
                    </li>
                }
                <hr className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`} />
                <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
                    <button
                        ref={accessibilityButtonRef}
                        className="link-secundario focusable-red"
                        onClick={() => { 
                            accessibility.setVisible(true);
                            showADropdown.setVisible(false)
                        }}
                    >
                        {textosInterfaz?.accesibilidad || 'Accesibilidad'}
                    </button>
                </li>
                <li className={`${visibleDropdown ? 'activeMode' : 'inactiveMode'}`}>
                    <Link
                        to="/tienda/institucional/contactanos"
                        className="link-secundario focusable-red"
                        onClick={()=>showADropdown.setVisible(false)}
                    >
                        {textosInterfaz?.contacto || 'Contacto'}
                    </Link>
                </li>
            </ul>
        </div>

    )
}