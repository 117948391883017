import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { store } from "../../../Store";
import { useAccessibility } from "../../../../hooks/useAccessibility";

import { DropdownPlanes } from "./DropdownPlanes";
import { DropdownComunidad } from "./DropdownComunidad";
import { NotificationButton } from "../../components/notification-button";
import { LangButton } from "../../../language/lang-button";

/**
 * Componente con las opciones del header sin login. (Agenda un demo, Planes, libros , comunidad, iniciar sesión, ajustes, notificaciones)
 * @param {object} textosInterfaz - objeto que contiene los textos de interfaz
 * @returns {JSX.Element} NavbarNav
 */
export const NavbarNav = ({ textosInterfaz, lang }) => {

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownCommunity, setOpenDropdownCommunity] = useState(false);
  const [urlDemo, setUrlDemo] = useState('/tienda/institucional/es/cita');
  const location = useLocation();
  const planesRef = useRef();
  const comunidadRef = useRef();

  const globalState = useContext(store);

  const accessibility = useAccessibility();
  const accessibilityButtonRef = useRef(null);

  useEffect(() => {
    const newUrl= lang === "es" ? "/tienda/institucional/es/cita" : "/tienda/institucional/en/cita";
    setUrlDemo(newUrl)
  },[lang])

  /*Asigna el botón que abre las opciones de accesibilidad */
  useEffect(() => {
    if (accessibilityButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "accessibility.triggerButton",
        value: accessibilityButtonRef,
      });
    }
  }, [accessibilityButtonRef]);

  /**Función que permite cerrar el dropdown dando click fuera de él*/
  useEffect(() => {
    let handler = (e) => {
      if (location.pathname !== '/institucional/formulario' && !planesRef.current.contains(e.target)) {
        setOpenDropdown(false)
      }
    }
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  }, [])

  /**Función que permite cerrar el dropdown Comunidad dando click fuera de él*/
  useEffect(() => {
    if (comunidadRef.current) {
      let handlerComunidad = (e) => {
        if (location.pathname !== '/institucional/formulario' && !comunidadRef.current.contains(e.target)) {
          setOpenDropdownCommunity(false)
        }
      }
      document.addEventListener("mousedown", handlerComunidad);

      return () => {
        document.removeEventListener("mousedown", handlerComunidad)
      }
    }

  }, [comunidadRef])

  /*cierra el dropdown al hacer scroll al inicio de la página */
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1 && window.scrollY < 10) {
        setOpenDropdown(false)
        setOpenDropdownCommunity(false)
      }
    });
  }, [accessibility.state.visible])

  return (
    <>
      <ul className="option-nav d-flex justify-content-end aling-items-center">
        {location.pathname !== '/institucional/formulario' &&
          <>
            <li className="mx-3">
              <Link
                to={urlDemo}
                className={`focusable-red ${location.pathname === '/tienda/institucional/es/demo' || location.pathname === '/tienda/institucional/en/demo' ? "btn-demo activeMode" : "btn-demo "}`}
              >{textosInterfaz?.agendar || 'Agendar demo'}</Link>
            </li>
            <li
              ref={planesRef}
              className={`${location.pathname === '/que-es/colegios' ||
                location.pathname === '/que-es/bibliotecas' ||
                location.pathname === '/tienda/plan-casa' ?
                "position-relative activeMode mx-3"
                :
                "position-relative mx-3"
                }`}
            >
              <button
                className="focusable-red"
                onClick={() => {
                  setOpenDropdown(!openDropdown)
                }}
                style={{ color: 'var(--onsecondary-color)' }}
              >{textosInterfaz?.quiero_make || 'Planes'}</button>

              <div className={`dropdownMenu ${openDropdown ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownPlanes
                  setOpenDropdown={setOpenDropdown}
                  openDropdown={openDropdown}
                  textosInterfaz={textosInterfaz}
                />
              </div>

            </li>
            <li className={`${location.pathname === '/home' ? "activeMode mx-3" : "mx-3"}`}>
              <Link
                to="/libros"
                className="focusable-red"
              >{textosInterfaz?.libros || 'Libros'}</Link>
            </li>
            <li
              ref={comunidadRef}
              className={`position-relative ${location.pathname === '/club-makemake' ? "activeMode mx-3" : "mx-3"}`}
            >
              <button
                className="focusable-red"
                onClick={() => {
                  setOpenDropdown(false);
                  setOpenDropdownCommunity(true);
                }}
                style={{ color: 'var(--onsecondary-color)' }}
              >
                {textosInterfaz?.comunidad || 'Comunidad'}
              </button>

              <div className={`dropdownMenu dropDownComunidad ${openDropdownCommunity ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownComunidad
                  setOpenDropdown={setOpenDropdownCommunity}
                  openDropdown={openDropdownCommunity}
                  textosInterfaz={textosInterfaz}
                />
              </div>
            </li>
            <li className={`${location.pathname === '/login' ? "activeMode mx-3" : "mx-3"}`}>
              <Link
                to="/login"
                className="focusable-red"
              >{textosInterfaz?.iniciar_sesion || 'Iniciar sesión'}</Link>
            </li>
          </>
        }


        <li className={`${accessibility.state.visible ? 'btn-setting activeMode mx-1' : 'btn-setting mx-1'}`}>
          <button
            ref={accessibilityButtonRef}
            aria-label="Ajustes -Settings"
            className={`focusable-red w-100 h-100 px-1`}
            onClick={() => { accessibility.setVisible(!accessibility?.state?.visible) }}
          >
            <Icon
              /* icon="material-symbols:settings" */
              icon="fontisto:universal-acces"
              width={20}
            />
          </button>
        </li>

        <li>
          <LangButton />
        </li>


        <NotificationButton />
      </ul>

    </>
  )
}