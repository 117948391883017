import React, { useRef } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Autoplay, FreeMode, Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

//import { usePublishersData } from "../../que-es/home/hooks/use-publishers";

import { ASSETS_URL } from "../../components/Store";
import { Img } from "../../components/shared/img";
import normalizeString from "../../js/utilities/normalizeString";

import { usePublishersBooksData } from "../hooks/use-publishers-books";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";
import { NavigationButtonsBooks } from "../components/navigation-buttons";

/**
 * Retorna las editoriales de libros
 * @returns {JSX.Element} OurPublishersHome
 */
export const OurPublishersBooks = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: 'en' });
	const textos = useTranslations({ section: "que_es", lang: lang });
	const publishersTexts = textos?.editoriales;
	const publishersData = usePublishersBooksData();
	const publishers = publishersData.publishers;
	const loadingPublishers = publishersData.loading;
	const prevButtonRef = useRef(null);
	const nextButtonRef = useRef(null);

	const updateNavigation = (swiper) => {
		swiper.params.navigation.prevEl = prevButtonRef?.current
		swiper.params.navigation.nextEl = nextButtonRef?.current
		swiper.navigation.destroy()
		swiper.navigation.init()
		swiper.navigation.update()
	}

	/**
* Esta función transforma el nombre de la editorial en un parametro buscable 
* @param {string} caracter //string: caracter que debe ser reemplazado por nuevoCaracter
* @param {string} nuevoCaracter //string: nuevo caracter
* @param {string} texto //string: nombre de la editorial
* @returns 
*/
	const newUrlEditorial = (caracter, nuevoCaracter, texto) => {
		var buscarCaracter = texto.search(caracter);
		var newUrl;
		if (buscarCaracter === -1) {
			newUrl = normalizeString(texto).replace(caracter, nuevoCaracter);
			return newUrl;
		} else {
			return normalizeString(texto);
		}
	};

	return (
		<div className="surface--bg py-4 onbackground-primary--color">
			<div className="row p-3 mx-md-5">
				<div className="col-md-9 col-lg-10 d-flex flex-nowrap justify-content-center align-items-center">
					<p className="flex-grow publisher-title secondary-inverse--color font-weight-bold">
						{publishersTexts?.titulo || "Nuestras editoriales"}
					</p>
					<div className="flex-grow-1 secondary-inverse--bg ml-4" style={{ height: 1 }}></div>
				</div>

				<div className="col-md-3 col-lg-2">
					<NavigationButtonsBooks nextButtonRef={nextButtonRef} prevButtonRef={prevButtonRef} />
				</div>
			</div>

			{loadingPublishers ? (
				<div className="d-flex justify-content-center align-items-center secondary--color">
					<Icon icon="fa:spinner" width={40} className={`spinner-rotation`} />
				</div>
			) : (
				<>
					<Swiper
						className="px-5"
						style={{ overflowX: "hidden" }}
						slidesPerView={"auto"}
						freeMode={true}
						direction="horizontal"
						autoplay={{
							disableOnInteraction: false,
						}}
						mousewheel={{
							forceToAxis: false,
						}}
						navigation={{
							nextEl: nextButtonRef?.current,
							prevEl: prevButtonRef?.current,
						}}
						onSwiper={(swiper) => {
							updateNavigation(swiper);
						}}
						modules={[Autoplay, Navigation, Mousewheel, FreeMode]}
					>
						{publishers.map((publisher, i) => {
							return (
								<SwiperSlide
									key={i}
									className="focusable-by-children-secondary d-flex justify-content-center align-items-center mx-3 p-0"
									style={{
										minHeight: 150,
										height: 150,
										maxWidth: 150,
									}}
								>
									<Link to={`/editorial/${newUrlEditorial("---", "-", publisher?.nombreEditorial || '')}`}>
										<Img
											key={i}
											className="img-fluid"
											style={{
												aspectRatio: "16/9",
												objectPosition: "50% 50%",
												objectFit: "contain",
												filter: "grayscale(100%)",
											}}
											src={`${ASSETS_URL}${publisher?.logo}`}
											alt={publisher?.nombreEditorial}
										/>
									</Link>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</>
			)}
		</div>
	);
};
